var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$screen.width <= 576
    ? _c(
        "div",
        { key: `mobile-${_vm.generateKey}`, attrs: { id: "contract-detail" } },
        [
          _c("transition", { attrs: { name: "slide" } }, [
            _c(
              "div",
              {
                class: `container-mobile${!_vm.isFilmSingle ? " pb-135" : ""}`,
              },
              [
                _c("a", { ref: "scrollTop" }),
                _c(
                  "div",
                  { staticClass: "my-contract-mobile p-3" },
                  [
                    _c("div", { staticClass: "title" }, [
                      _c("h2", [_vm._v(_vm._s(_vm.FormMSG(41, "Contract")))]),
                      !_vm.isFilmSingle && !_vm.isFilmSingleFree
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.titleStartDate) +
                                " - " +
                                _vm._s(_vm.titleEndDate)
                            ),
                          ])
                        : _vm._e(),
                      !_vm.isFilmSingle
                        ? _c(
                            "div",
                            { on: { click: _vm.handleClickHeader } },
                            [
                              !_vm.configAccordion.isVisible
                                ? _c("chevron-down", {
                                    staticClass: "icon",
                                    attrs: { color: "#06263E", size: 22 },
                                  })
                                : _vm._e(),
                              _vm.configAccordion.isVisible
                                ? _c("chevron-up", {
                                    staticClass: "icon",
                                    attrs: { color: "#06263E", size: 22 },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "b-collapse",
                      {
                        staticClass: "content form pt-3",
                        attrs: {
                          visible:
                            _vm.configAccordion.isVisible || _vm.isFilmSingle,
                        },
                      },
                      [
                        _c("department-selection", {
                          ref: "depfun",
                          attrs: {
                            "edit-data": _vm.contract,
                            "disable-edit": _vm.isEditable() === false,
                            "burger-field": "",
                            "is-submitted": _vm.isHandledCcn2642,
                            "refresh-list": _vm.refreshDepartmentSelection,
                            "from-job-request": _vm.fromJobRequest,
                            useMemoDeal: _vm.useMemoDeal,
                          },
                          on: {
                            "department-function-selector:departmentSelected":
                              _vm.handleDepartmentChange,
                            "department-function-selector:functionSelected":
                              _vm.handleFunctionChange,
                            "department-function-selection:init-finish":
                              _vm.handleDepartmentSelectionInitFinish,
                            "active-template:status":
                              _vm.handleChangeStatusTemplate,
                          },
                        }),
                        _c(
                          "b-form",
                          { on: { submit: _vm.submitForm } },
                          [
                            !_vm.isFilmSingle
                              ? _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            directives: [
                                              {
                                                name: "uni-for",
                                                rawName: "v-uni-for",
                                                value: "startDate",
                                                expression: "'startDate'",
                                              },
                                            ],
                                            attrs: {
                                              label: _vm.FormMSG(
                                                10,
                                                "Start date"
                                              ),
                                            },
                                          },
                                          [
                                            _c("v-date-picker", {
                                              attrs: {
                                                locale: _vm.navigatorLang,
                                                masks: _vm.startDateMask,
                                                timezone: "UTC",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      inputValue,
                                                      togglePopover,
                                                      inputEvents,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "b-input-group",
                                                          _vm._g(
                                                            {
                                                              on: {
                                                                focus:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.isEditable()
                                                                      ? togglePopover
                                                                      : void 0
                                                                  },
                                                              },
                                                            },
                                                            _vm.isEditable()
                                                              ? inputEvents
                                                              : void 0
                                                          ),
                                                          [
                                                            _c("b-form-input", {
                                                              directives: [
                                                                {
                                                                  name: "mask",
                                                                  rawName:
                                                                    "v-mask",
                                                                  value:
                                                                    "##/##/####",
                                                                  expression:
                                                                    "'##/##/####'",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              class: {
                                                                "is-invalid":
                                                                  _vm.$v
                                                                    .contract
                                                                    .startDate
                                                                    .$error,
                                                              },
                                                              staticStyle: {
                                                                "border-radius":
                                                                  "10px 0 0 10px",
                                                              },
                                                              attrs: {
                                                                value:
                                                                  inputValue,
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    164,
                                                                    "DD/MM/YYYY"
                                                                  ),
                                                                disabled:
                                                                  _vm.isEditable() ===
                                                                  false,
                                                              },
                                                            }),
                                                            _c(
                                                              "b-input-group-append",
                                                              [
                                                                _c(
                                                                  "b-input-group-text",
                                                                  {
                                                                    staticClass:
                                                                      "cursor-pointer bg-color-white input-group-text-bg-white",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.isEditable()
                                                                            ? togglePopover
                                                                            : void 0
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getLucideIcon(
                                                                        "Calendar"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          color:
                                                                            "rgba(6, 38, 62, .6)",
                                                                          size: 18,
                                                                          "stroke-width": 2.25,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm.$v.contract
                                                          .startDate.$error
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "invalid-feedback",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        165,
                                                                        "Start date contract, a valid date is required"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                409544784
                                              ),
                                              model: {
                                                value: _vm.contract.startDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.contract,
                                                    "startDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "contract.startDate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            directives: [
                                              {
                                                name: "uni-for",
                                                rawName: "v-uni-for",
                                                value: "endDate",
                                                expression: "'endDate'",
                                              },
                                            ],
                                            attrs: {
                                              label: _vm.FormMSG(
                                                11,
                                                "End date:"
                                              ),
                                            },
                                          },
                                          [
                                            _c("v-date-picker", {
                                              attrs: {
                                                locale: _vm.navigatorLang,
                                                masks: _vm.endDateMask,
                                                timezone: "UTC",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      inputValue,
                                                      togglePopover,
                                                      inputEvents,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "b-input-group",
                                                          _vm._g(
                                                            {
                                                              on: {
                                                                focus:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.isEditable()
                                                                      ? togglePopover
                                                                      : void 0
                                                                  },
                                                              },
                                                            },
                                                            _vm.isEditable()
                                                              ? inputEvents
                                                              : void 0
                                                          ),
                                                          [
                                                            _c("b-form-input", {
                                                              directives: [
                                                                {
                                                                  name: "mask",
                                                                  rawName:
                                                                    "v-mask",
                                                                  value:
                                                                    "##/##/####",
                                                                  expression:
                                                                    "'##/##/####'",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              class: {
                                                                "is-invalid":
                                                                  _vm.$v
                                                                    .contract
                                                                    .endDate
                                                                    .$error,
                                                              },
                                                              staticStyle: {
                                                                "border-radius":
                                                                  "10px 0 0 10px",
                                                              },
                                                              attrs: {
                                                                value:
                                                                  inputValue,
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    164,
                                                                    "DD/MM/YYYY"
                                                                  ),
                                                                disabled:
                                                                  _vm.isEditable() ===
                                                                  false,
                                                              },
                                                            }),
                                                            _c(
                                                              "b-input-group-append",
                                                              [
                                                                _c(
                                                                  "b-input-group-text",
                                                                  {
                                                                    staticClass:
                                                                      "cursor-pointer bg-color-white input-group-text-bg-white",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.isEditable()
                                                                            ? togglePopover
                                                                            : void 0
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getLucideIcon(
                                                                        "Calendar"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          color:
                                                                            "rgba(6, 38, 62, .6)",
                                                                          size: 18,
                                                                          "stroke-width": 2.25,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm.$v.contract.endDate
                                                          .$error
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "invalid-feedback",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        166,
                                                                        "End date contract, a valid date is required"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3543826460
                                              ),
                                              model: {
                                                value: _vm.contract.endDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.contract,
                                                    "endDate",
                                                    $$v
                                                  )
                                                },
                                                expression: "contract.endDate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.isFilmSingle
                              ? _c(
                                  "b-form-group",
                                  {
                                    directives: [
                                      {
                                        name: "uni-for",
                                        rawName: "v-uni-for",
                                        value: "daysforeseens",
                                        expression: "'daysforeseens'",
                                      },
                                    ],
                                    attrs: {
                                      label: _vm.FormMSG(
                                        12,
                                        "Number of working days foreseen:"
                                      ),
                                      "label-cols": 12,
                                      "label-cols-md": 3,
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      directives: [
                                        {
                                          name: "uni-id",
                                          rawName: "v-uni-id",
                                          value: "daysforeseens",
                                          expression: "'daysforeseens'",
                                        },
                                      ],
                                      attrs: {
                                        disabled: _vm.isEditable() === false,
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.contract.daysForeseen,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.contract,
                                            "daysForeseen",
                                            $$v
                                          )
                                        },
                                        expression: "contract.daysForeseen",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.isFilmSingle
                              ? _c(
                                  "b-form-group",
                                  {
                                    directives: [
                                      {
                                        name: "uni-for",
                                        rawName: "v-uni-for",
                                        value: "employeeType",
                                        expression: "'employeeType'",
                                      },
                                    ],
                                    attrs: {
                                      label: _vm.FormMSG(26, "Employee type"),
                                    },
                                  },
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        disabled: _vm.isEditable() === false,
                                        options: _vm.employeeStatusOptions,
                                      },
                                      model: {
                                        value: _vm.contract.employeeStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.contract,
                                            "employeeStatus",
                                            $$v
                                          )
                                        },
                                        expression: "contract.employeeStatus",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.isFilmSingle && !_vm.isFilmSingleFree
                              ? _c(
                                  "b-row",
                                  [
                                    [0, 1, 3, 4].includes(
                                      _vm.analyticPeriodType
                                    )
                                      ? _c(
                                          "b-col",
                                          { attrs: { sm: "12" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                directives: [
                                                  {
                                                    name: "uni-for",
                                                    rawName: "v-uni-for",
                                                    value:
                                                      "costCenterPrepsType",
                                                    expression:
                                                      "'costCenterPrepsType'",
                                                  },
                                                ],
                                                attrs: {
                                                  label:
                                                    _vm.labelCostCenterPreps,
                                                },
                                              },
                                              [
                                                _c("treeselect", {
                                                  class: {
                                                    "is-invalid":
                                                      _vm.isSubmitted &&
                                                      _vm.$v.contract
                                                        .costCenterPrepsType
                                                        .$error,
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      _vm.isEditable() ===
                                                      false,
                                                    multiple: false,
                                                    clearable: false,
                                                    options:
                                                      _vm.costCenterPrepsOptions,
                                                    "disable-branch-nodes": true,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "option-label",
                                                        fn: function ({
                                                          node,
                                                        }) {
                                                          return _c("div", {}, [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "treeselect-label",
                                                                attrs: {
                                                                  title:
                                                                    node.label,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    node.label
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ])
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2657349975
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.contract
                                                        .costCenterPrepsType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.contract,
                                                        "costCenterPrepsType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "contract.costCenterPrepsType",
                                                  },
                                                }),
                                                _vm.isSubmitted &&
                                                _vm.$v.contract
                                                  .costCenterPrepsType.$error
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "invalid-feedback",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                61,
                                                                _vm.msgPrep
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    [1, 2, 4].includes(_vm.analyticPeriodType)
                                      ? _c(
                                          "b-col",
                                          { attrs: { sm: "12" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                directives: [
                                                  {
                                                    name: "uni-for",
                                                    rawName: "v-uni-for",
                                                    value:
                                                      "costCenterShootingType",
                                                    expression:
                                                      "'costCenterShootingType'",
                                                  },
                                                ],
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    28,
                                                    "Cost center shooting"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("treeselect", {
                                                  class: {
                                                    "is-invalid":
                                                      _vm.isSubmitted &&
                                                      _vm.$v.contract
                                                        .costCenterShootingType
                                                        .$error,
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      _vm.isEditable() ===
                                                      false,
                                                    multiple: false,
                                                    clearable: false,
                                                    options:
                                                      _vm.costCenterShootingOptions,
                                                    "disable-branch-nodes": true,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "option-label",
                                                        fn: function ({
                                                          node,
                                                        }) {
                                                          return _c("div", {}, [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "treeselect-label",
                                                                attrs: {
                                                                  title:
                                                                    node.label,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    node.label
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ])
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2657349975
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.contract
                                                        .costCenterShootingType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.contract,
                                                        "costCenterShootingType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "contract.costCenterShootingType",
                                                  },
                                                }),
                                                _vm.isSubmitted &&
                                                _vm.$v.contract
                                                  .costCenterShootingType.$error
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "invalid-feedback",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                62,
                                                                _vm.msgShooting
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    [2, 3, 4].includes(_vm.analyticPeriodType)
                                      ? _c(
                                          "b-col",
                                          { attrs: { sm: "12" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                directives: [
                                                  {
                                                    name: "uni-for",
                                                    rawName: "v-uni-for",
                                                    value: "costCenterWrapType",
                                                    expression:
                                                      "'costCenterWrapType'",
                                                  },
                                                ],
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    29,
                                                    "Cost center wrap"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("treeselect", {
                                                  class: {
                                                    "is-invalid":
                                                      _vm.isSubmitted &&
                                                      _vm.$v.contract
                                                        .costCenterWrapType
                                                        .$error,
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      _vm.isEditable() ===
                                                      false,
                                                    multiple: false,
                                                    clearable: false,
                                                    options:
                                                      _vm.costCenterWrapOptions,
                                                    "disable-branch-nodes": true,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "option-label",
                                                        fn: function ({
                                                          node,
                                                        }) {
                                                          return _c("div", {}, [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "treeselect-label",
                                                                attrs: {
                                                                  title:
                                                                    node.label,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    node.label
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ])
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2657349975
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.contract
                                                        .costCenterWrapType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.contract,
                                                        "costCenterWrapType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "contract.costCenterWrapType",
                                                  },
                                                }),
                                                _vm.isSubmitted &&
                                                _vm.$v.contract
                                                  .costCenterWrapType.$error
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "invalid-feedback",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                63,
                                                                _vm.msgWrap
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-col",
                                      {
                                        attrs: {
                                          sm: "12",
                                          md: "12",
                                          lg: "3",
                                          xl: "3",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            directives: [
                                              {
                                                name: "uni-for",
                                                rawName: "v-uni-for",
                                                value: "bcfRate",
                                                expression: "'bcfRate'",
                                              },
                                            ],
                                            attrs: {
                                              label: _vm.FormMSG(
                                                35,
                                                "Budget cost factor"
                                              ),
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              directives: [
                                                {
                                                  name: "uni-id",
                                                  rawName: "v-uni-id",
                                                  value: "bcfRate",
                                                  expression: "'bcfRate'",
                                                },
                                              ],
                                              attrs: {
                                                type: "number",
                                                step: "0.00000000001",
                                                min: "1",
                                                placeholder: "1.0",
                                                disabled:
                                                  _vm.isEditable() === false,
                                              },
                                              model: {
                                                value:
                                                  _vm.contract
                                                    .companyCostFactor,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.contract,
                                                    "companyCostFactor",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "contract.companyCostFactor",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "info-message" },
                                              [
                                                _c(
                                                  "div",
                                                  [
                                                    _c("info", {
                                                      attrs: { size: 16 },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "label" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          36,
                                                          "budget cost = salary*factor"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        attrs: {
                                          sm: "12",
                                          md: "12",
                                          lg: "3",
                                          xl: "3",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            directives: [
                                              {
                                                name: "uni-for",
                                                rawName: "v-uni-for",
                                                value: "salType",
                                                expression: "'salType'",
                                              },
                                            ],
                                            attrs: {
                                              label: _vm.FormMSG(
                                                535,
                                                "Salary type"
                                              ),
                                            },
                                          },
                                          [
                                            _c("b-form-select", {
                                              directives: [
                                                {
                                                  name: "uni-id",
                                                  rawName: "v-uni-id",
                                                  value: "salType",
                                                  expression: "'salType'",
                                                },
                                              ],
                                              attrs: {
                                                options: _vm.salaryTypeOptions,
                                                disabled:
                                                  _vm.isEditable() === false,
                                              },
                                              model: {
                                                value: _vm.contract.salaryType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.contract,
                                                    "salaryType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "contract.salaryType",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.isFilmSingle && !_vm.isFilmSingleFree
                              ? _c(
                                  "b-row",
                                  [
                                    _c("b-col", { attrs: { sm: "12" } }, [
                                      _c(
                                        "fieldset",
                                        { staticClass: "card-inside" },
                                        [
                                          _c(
                                            "legend",
                                            { staticClass: "card-inside" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    37,
                                                    "Document package"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-row",
                                            {
                                              staticClass:
                                                "inline-flex align-items-center",
                                            },
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    sm: "12",
                                                    md: "12",
                                                    lg: "6",
                                                    xl: "6",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "uni-for",
                                                          rawName: "v-uni-for",
                                                          value:
                                                            "documentPackageId",
                                                          expression:
                                                            "'documentPackageId'",
                                                        },
                                                      ],
                                                      attrs: {
                                                        label: _vm.FormMSG(
                                                          38,
                                                          "Assign document package"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        staticClass:
                                                          "fix-clearable-disabled",
                                                        class: {
                                                          "is-invalid":
                                                            _vm.isHandleSendPackage &&
                                                            _vm.$v.contract
                                                              .documentPackageId
                                                              .$error,
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            _vm.isEditable() ===
                                                            false,
                                                          options:
                                                            _vm.documentPackageOptions,
                                                          id: "documentPackageId",
                                                          label: "name",
                                                          reduce: (option) =>
                                                            option.id,
                                                          clearable: false,
                                                        },
                                                        on: {
                                                          "option:selected":
                                                            _vm.selectDocumentPackage,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .documentPackageId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "documentPackageId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.documentPackageId",
                                                        },
                                                      }),
                                                      _vm.isHandleSendPackage &&
                                                      !_vm.$v.contract
                                                        .documentPackageId
                                                        .greaterThanZero
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "invalid-feedback",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      69,
                                                                      "Please, choose a document package"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    sm: "12",
                                                    md: "12",
                                                    lg: "6",
                                                    xl: "6",
                                                  },
                                                },
                                                [
                                                  _vm.contract
                                                    .documentPackageId > 0
                                                    ? _c(
                                                        "b-row",
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                sm: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "btn-rounded",
                                                                  on: {
                                                                    click:
                                                                      _vm.handleViewDocumentPackage,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "icon",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "eye",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "#5B6164D6",
                                                                              size: 16,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "label",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              39,
                                                                              "View document package"
                                                                            )
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.hideSendPackageBtn
                                                    ? _c(
                                                        "b-row",
                                                        { staticClass: "pt-3" },
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                sm: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "btn-rounded",
                                                                  on: {
                                                                    click:
                                                                      _vm.handleResendDocumentPackage,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "icon",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "files",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "#5B6164D6",
                                                                              size: 16,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "label",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              42,
                                                                              "Send new document package"
                                                                            )
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("memo-deal", {
                              ref: "memo",
                              attrs: {
                                "edit-data": _vm.getMemoDealInfoFromContract(),
                                "disable-edit": !_vm.isEditable(),
                                "start-date-update": _vm.contract.startDate,
                                "end-date-update": _vm.contract.endDate,
                                "days-foreseen-update":
                                  _vm.contract.daysForeseen,
                                "employee-status-update":
                                  _vm.contract.employeeStatus,
                                "cost-center-preps-type-update":
                                  _vm.contract.costCenterPrepsType,
                                "cost-center-shooting-type-update":
                                  _vm.contract.costCenterShootingType,
                                "cost-center-wrap-type-update":
                                  _vm.contract.costCenterWrapType,
                                "company-cost-factor-update":
                                  _vm.contract.companyCostFactor,
                                "document-package-id-update":
                                  _vm.contract.documentPackageId,
                                "is-submitted": _vm.isSubmitted,
                                "show-pay-code": _vm.showPayCode,
                                "hide-actions": "",
                              },
                              on: {
                                change: _vm.handleMemoDealChange,
                                "memo-deal:fields:invalid":
                                  _vm.isInvalidateFields,
                                "memo-deal:fields:clear-error":
                                  _vm.clearFieldsError,
                                "memo-deal:department-function:required":
                                  _vm.handleCcn2642,
                              },
                            }),
                            _vm.isEditable()
                              ? _c(
                                  "b-row",
                                  { staticClass: "footer-fixed" },
                                  [
                                    !_vm.hideBtnDelete
                                      ? _c(
                                          "b-col",
                                          {
                                            attrs: {
                                              sm: "12",
                                              md: "12",
                                              lg: `${_vm.activePaste ? 3 : 4}`,
                                              xl: `${_vm.activePaste ? 3 : 4}`,
                                            },
                                          },
                                          [
                                            !_vm.isFilmSingle
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      block: "",
                                                      disabled:
                                                        _vm.disableDelete,
                                                      variant: "danger",
                                                      size: "lg",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.delContract()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            31,
                                                            "Delete contract"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !_vm.hideBtnCopy
                                      ? _c(
                                          "b-col",
                                          {
                                            class: `${
                                              _vm.$screen.width <= 992
                                                ? "my-2"
                                                : ""
                                            }`,
                                            attrs: {
                                              sm: "12",
                                              md: "12",
                                              lg: `${_vm.activePaste ? 3 : 4}`,
                                              xl: `${_vm.activePaste ? 3 : 4}`,
                                            },
                                          },
                                          [
                                            !_vm.isFilmSingle
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      block: "",
                                                      size: "lg",
                                                      variant:
                                                        "outline-secondary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.copyContract()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            32,
                                                            "Copy contract"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.activePaste && !_vm.hideBtnSaveTemplate
                                      ? _c(
                                          "b-col",
                                          {
                                            class: `${
                                              _vm.$screen.width <= 992
                                                ? "mb-2"
                                                : ""
                                            }`,
                                            attrs: {
                                              sm: "12",
                                              md: "12",
                                              lg: `${_vm.activePaste ? 3 : 4}`,
                                              xl: `${_vm.activePaste ? 3 : 4}`,
                                            },
                                          },
                                          [
                                            _vm.activePaste
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      block: "",
                                                      variant:
                                                        "outline-secondary",
                                                      size: "lg",
                                                    },
                                                    on: {
                                                      click: _vm.pasteContract,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            33,
                                                            "Paste contract"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-2",
                                        attrs: {
                                          sm: "12",
                                          md: "12",
                                          lg: `${_vm.activePaste ? 3 : 4}`,
                                          xl: `${_vm.activePaste ? 3 : 4}`,
                                        },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              block: "",
                                              disabled: _vm.disableSave,
                                              type: "submit",
                                              size: "lg",
                                              variant: "outline-primary",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    30,
                                                    "Save contract"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      )
    : _c(
        "div",
        {
          key: `web-${_vm.generateKey}`,
          staticClass: "animated fadeIn",
          attrs: { id: "contract-detail" },
        },
        [
          _c("a", { ref: "scrollTop" }),
          _c(
            "div",
            { staticClass: "card card-border-blue-light" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "container-header-card-collapsible cursor-pointer",
                  on: { click: _vm.handleClickHeader },
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _c("div", { staticClass: "label w-90" }, [
                      _c("h4", [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(41, "Contract")) +
                            "\n\t\t\t\t\t\t"
                        ),
                        !_vm.isFilmSingle && !_vm.isFilmSingleFree
                          ? _c("div", { staticClass: "ml-3" }, [
                              _vm._v(
                                _vm._s(_vm.titleStartDate) +
                                  " - " +
                                  _vm._s(_vm.titleEndDate)
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    !_vm.isFilmSingle
                      ? _c(
                          "div",
                          { staticClass: "icon w-10" },
                          [
                            !_vm.configAccordion.isVisible
                              ? _c("chevron-down", {
                                  attrs: { color: "#225CBD", size: 22 },
                                })
                              : _vm._e(),
                            _vm.configAccordion.isVisible
                              ? _c("chevron-up", {
                                  attrs: { color: "#225CBD", size: 22 },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
              _c(
                "b-collapse",
                {
                  attrs: {
                    visible: _vm.configAccordion.isVisible || _vm.isFilmSingle,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "pt-4", class: _vm.fillClassContainer },
                    [
                      _c(
                        "b-form",
                        { on: { submit: _vm.submitForm } },
                        [
                          _c("div", { staticClass: "card-collapsible px-0" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "header-card-collapsible d-flex flex-row align-items-center cursor-pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCollapsibleHeader(
                                      "work-general-information"
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "title w-90" },
                                  [
                                    _c("b-icon", {
                                      staticStyle: {
                                        color: "rgba(6, 38, 62, 0.84)",
                                      },
                                      attrs: { icon: "file-earmark-text" },
                                    }),
                                    _c("span", { staticClass: "ml-3" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(
                                            25,
                                            "Work general information"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "cursor-pointer d-flex justify-content-end w-10",
                                  },
                                  [
                                    !_vm.configCardAccordion[
                                      "work-general-information"
                                    ].isVisible
                                      ? _c(_vm.getIconLucide("ChevronDown"), {
                                          tag: "component",
                                          attrs: { color: "#06263E", size: 22 },
                                        })
                                      : _vm._e(),
                                    _vm.configCardAccordion[
                                      "work-general-information"
                                    ].isVisible
                                      ? _c(_vm.getIconLucide("ChevronUp"), {
                                          tag: "component",
                                          attrs: { color: "#06263E", size: 22 },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "b-collapse",
                            {
                              attrs: {
                                visible:
                                  _vm.configCardAccordion[
                                    "work-general-information"
                                  ].isVisible,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "content-card-collapsible" },
                                [
                                  _vm.fromJobRequest
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "8",
                                                md: "3",
                                                lg: "3",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      1897,
                                                      "Original department"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      placeholder: _vm.FormMSG(
                                                        1579,
                                                        "Enter a department"
                                                      ),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.$v
                                                          .originalDepartment
                                                          .$model,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.$v
                                                            .originalDepartment,
                                                          "$model",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "$v.originalDepartment.$model",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              staticStyle: {
                                                "padding-top": "20px",
                                              },
                                              attrs: {
                                                sm: "8",
                                                md: "3",
                                                lg: "3",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    block: "",
                                                    variant: "outline-primary",
                                                    disabled:
                                                      _vm.disableBtnCreateDepartment,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.handleClickAddDepartment,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          1898,
                                                          "Create department"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "8",
                                                md: "3",
                                                lg: "3",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      1899,
                                                      "Original function"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      placeholder: _vm.FormMSG(
                                                        1784,
                                                        "Enter a function"
                                                      ),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.$v.originalFunction
                                                          .$model,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.$v
                                                            .originalFunction,
                                                          "$model",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "$v.originalFunction.$model",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              staticStyle: {
                                                "padding-top": "20px",
                                              },
                                              attrs: {
                                                sm: "8",
                                                md: "3",
                                                lg: "3",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    block: "",
                                                    variant: "outline-primary",
                                                    disabled:
                                                      _vm.disableBtnCreateFunction,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.handleClickCreateFunction,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          1900,
                                                          "Create function"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("department-selection", {
                                    ref: "depfun",
                                    attrs: {
                                      "edit-data": _vm.contract,
                                      "disable-edit":
                                        _vm.isEditable() === false,
                                      "burger-field": "",
                                      "is-submitted": _vm.isHandledCcn2642,
                                      "refresh-list":
                                        _vm.refreshDepartmentSelection,
                                      "from-job-request": _vm.fromJobRequest,
                                      useMemoDeal: _vm.useMemoDeal,
                                      generateKey: _vm.generateKey,
                                    },
                                    on: {
                                      "department-function-selector:departmentSelected":
                                        _vm.handleDepartmentChange,
                                      "department-function-selector:functionSelected":
                                        _vm.handleFunctionChange,
                                      "department-function-selection:init-finish":
                                        _vm.handleDepartmentSelectionInitFinish,
                                      "active-template:status":
                                        _vm.handleChangeStatusTemplate,
                                    },
                                  }),
                                  !_vm.isFilmSingle && !_vm.isFilmSingleFree
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "12",
                                                lg: "3",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "startDate",
                                                      expression: "'startDate'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      10,
                                                      "Start date"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("v-date-picker", {
                                                    attrs: {
                                                      locale: _vm.navigatorLang,
                                                      masks: _vm.startDateMask,
                                                      timezone: "UTC",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function ({
                                                            inputValue,
                                                            togglePopover,
                                                            inputEvents,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "b-input-group",
                                                                _vm._g(
                                                                  {
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.isEditable()
                                                                            ? togglePopover()
                                                                            : void 0
                                                                        },
                                                                    },
                                                                  },
                                                                  _vm.isEditable()
                                                                    ? inputEvents
                                                                    : void 0
                                                                ),
                                                                [
                                                                  _c(
                                                                    "b-form-input",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "mask",
                                                                            rawName:
                                                                              "v-mask",
                                                                            value:
                                                                              "##/##/####",
                                                                            expression:
                                                                              "'##/##/####'",
                                                                          },
                                                                        ],
                                                                      staticClass:
                                                                        "form-control",
                                                                      class: {
                                                                        "is-invalid":
                                                                          _vm.$v
                                                                            .contract
                                                                            .startDate
                                                                            .$error,
                                                                      },
                                                                      staticStyle:
                                                                        {
                                                                          "border-radius":
                                                                            "10px 0 0 10px",
                                                                        },
                                                                      attrs: {
                                                                        value:
                                                                          inputValue,
                                                                        placeholder:
                                                                          _vm.FormMSG(
                                                                            164,
                                                                            "DD/MM/YYYY"
                                                                          ),
                                                                        disabled:
                                                                          _vm.isEditable() ===
                                                                          false,
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "b-input-group-append",
                                                                    [
                                                                      _c(
                                                                        "b-input-group-text",
                                                                        {
                                                                          staticClass:
                                                                            "cursor-pointer bg-color-white input-group-text-bg-white",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                _vm.isEditable()
                                                                                  ? togglePopover()
                                                                                  : void 0
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            _vm.getLucideIcon(
                                                                              "Calendar"
                                                                            ),
                                                                            {
                                                                              tag: "component",
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "rgba(6, 38, 62, .6)",
                                                                                  size: 18,
                                                                                  "stroke-width": 2.25,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm.$v.contract
                                                                .startDate
                                                                .$error
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "invalid-feedback",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              165,
                                                                              "Start date contract, a valid date is required"
                                                                            )
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      3824355538
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.contract.startDate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "startDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.startDate",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "12",
                                                lg: "3",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "endDate",
                                                      expression: "'endDate'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      11,
                                                      "End date (optional)"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("v-date-picker", {
                                                    attrs: {
                                                      locale: _vm.navigatorLang,
                                                      masks: _vm.endDateMask,
                                                      timezone: "UTC",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function ({
                                                            inputValue,
                                                            togglePopover,
                                                            inputEvents,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "b-input-group",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        _vm.isEditable() ===
                                                                        false,
                                                                    },
                                                                    on: {
                                                                      focus:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.isEditable()
                                                                            ? togglePopover()
                                                                            : void 0
                                                                        },
                                                                    },
                                                                  },
                                                                  _vm.isEditable()
                                                                    ? inputEvents
                                                                    : void 0
                                                                ),
                                                                [
                                                                  _c(
                                                                    "b-form-input",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "mask",
                                                                            rawName:
                                                                              "v-mask",
                                                                            value:
                                                                              "##/##/####",
                                                                            expression:
                                                                              "'##/##/####'",
                                                                          },
                                                                        ],
                                                                      staticClass:
                                                                        "form-control",
                                                                      class: {
                                                                        "is-invalid":
                                                                          _vm.$v
                                                                            .contract
                                                                            .endDate
                                                                            .$error,
                                                                      },
                                                                      staticStyle:
                                                                        {
                                                                          "border-radius":
                                                                            "10px 0 0 10px",
                                                                        },
                                                                      attrs: {
                                                                        value:
                                                                          inputValue,
                                                                        placeholder:
                                                                          _vm.FormMSG(
                                                                            164,
                                                                            "DD/MM/YYYY"
                                                                          ),
                                                                        disabled:
                                                                          _vm.isEditable() ===
                                                                          false,
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "b-input-group-append",
                                                                    [
                                                                      _c(
                                                                        "b-input-group-text",
                                                                        {
                                                                          staticClass:
                                                                            "cursor-pointer bg-color-white input-group-text-bg-white",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                _vm.isEditable()
                                                                                  ? togglePopover()
                                                                                  : void 0
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            _vm.getLucideIcon(
                                                                              "Calendar"
                                                                            ),
                                                                            {
                                                                              tag: "component",
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "rgba(6, 38, 62, .6)",
                                                                                  size: 18,
                                                                                  "stroke-width": 2.25,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm.$v.contract
                                                                .endDate.$error
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "invalid-feedback",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              166,
                                                                              "End date contract, a valid date is required"
                                                                            )
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      1998211243
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.contract.endDate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "endDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.endDate",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "12",
                                                lg: "3",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "daysforeseens",
                                                      expression:
                                                        "'daysforeseens'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      12,
                                                      "Number of workign days foreseen"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value: "daysforeseens",
                                                        expression:
                                                          "'daysforeseens'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                        false,
                                                      type: "number",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .daysForeseen,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "daysForeseen",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.daysForeseen",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "12",
                                                lg: "3",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "employeeType",
                                                      expression:
                                                        "'employeeType'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      26,
                                                      "Employee type"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("b-form-select", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                        false,
                                                      options:
                                                        _vm.employeeStatusOptions,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .employeeStatus,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "employeeStatus",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.employeeStatus",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.isFilmSingle && !_vm.isFilmSingleFree
                                    ? _c(
                                        "b-row",
                                        [
                                          [0, 1, 3, 4].includes(
                                            _vm.analyticPeriodType
                                          )
                                            ? _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    sm: "12",
                                                    md: "12",
                                                    lg: "6",
                                                    xl: "6",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "uni-for",
                                                          rawName: "v-uni-for",
                                                          value:
                                                            "costCenterPrepsType",
                                                          expression:
                                                            "'costCenterPrepsType'",
                                                        },
                                                      ],
                                                      attrs: {
                                                        label:
                                                          _vm.labelCostCenterPreps,
                                                      },
                                                    },
                                                    [
                                                      _c("treeselect", {
                                                        class: {
                                                          "is-invalid":
                                                            _vm.isSubmitted &&
                                                            _vm.$v.contract
                                                              .costCenterPrepsType
                                                              .$error,
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            _vm.isEditable() ===
                                                            false,
                                                          multiple: false,
                                                          clearable: false,
                                                          options:
                                                            _vm.costCenterPrepsOptions,
                                                          "disable-branch-nodes": true,
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "option-label",
                                                              fn: function ({
                                                                node,
                                                              }) {
                                                                return _c(
                                                                  "div",
                                                                  {},
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "treeselect-label",
                                                                        attrs: {
                                                                          title:
                                                                            node.label,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            node.label
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2657349975
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .costCenterPrepsType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "costCenterPrepsType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.costCenterPrepsType",
                                                        },
                                                      }),
                                                      _vm.isSubmitted &&
                                                      _vm.$v.contract
                                                        .costCenterPrepsType
                                                        .$error
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "invalid-feedback",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      61,
                                                                      _vm.msgPrep
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          [1, 2, 4].includes(
                                            _vm.analyticPeriodType
                                          )
                                            ? _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    sm: "12",
                                                    md: "12",
                                                    lg: "6",
                                                    xl: "6",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "uni-for",
                                                          rawName: "v-uni-for",
                                                          value:
                                                            "costCenterShootingType",
                                                          expression:
                                                            "'costCenterShootingType'",
                                                        },
                                                      ],
                                                      attrs: {
                                                        label: _vm.FormMSG(
                                                          28,
                                                          "Cost center shooting"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c("treeselect", {
                                                        class: {
                                                          "is-invalid":
                                                            _vm.isSubmitted &&
                                                            _vm.$v.contract
                                                              .costCenterShootingType
                                                              .$error,
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            _vm.isEditable() ===
                                                            false,
                                                          multiple: false,
                                                          clearable: false,
                                                          options:
                                                            _vm.costCenterShootingOptions,
                                                          "disable-branch-nodes": true,
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "option-label",
                                                              fn: function ({
                                                                node,
                                                              }) {
                                                                return _c(
                                                                  "div",
                                                                  {},
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "treeselect-label",
                                                                        attrs: {
                                                                          title:
                                                                            node.label,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            node.label
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2657349975
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .costCenterShootingType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "costCenterShootingType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.costCenterShootingType",
                                                        },
                                                      }),
                                                      _vm.isSubmitted &&
                                                      _vm.$v.contract
                                                        .costCenterShootingType
                                                        .$error
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "invalid-feedback",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      62,
                                                                      _vm.msgShooting
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          [2, 3, 4].includes(
                                            _vm.analyticPeriodType
                                          )
                                            ? _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    sm: "12",
                                                    md: "12",
                                                    lg: "6",
                                                    xl: "6",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "uni-for",
                                                          rawName: "v-uni-for",
                                                          value:
                                                            "costCenterWrapType",
                                                          expression:
                                                            "'costCenterWrapType'",
                                                        },
                                                      ],
                                                      attrs: {
                                                        label: _vm.FormMSG(
                                                          29,
                                                          "Cost center wrap"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c("treeselect", {
                                                        class: {
                                                          "is-invalid":
                                                            _vm.isSubmitted &&
                                                            _vm.$v.contract
                                                              .costCenterWrapType
                                                              .$error,
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            _vm.isEditable() ===
                                                            false,
                                                          multiple: false,
                                                          clearable: false,
                                                          options:
                                                            _vm.costCenterWrapOptions,
                                                          "disable-branch-nodes": true,
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "option-label",
                                                              fn: function ({
                                                                node,
                                                              }) {
                                                                return _c(
                                                                  "div",
                                                                  {},
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "treeselect-label",
                                                                        attrs: {
                                                                          title:
                                                                            node.label,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            node.label
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2657349975
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .costCenterWrapType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "costCenterWrapType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.costCenterWrapType",
                                                        },
                                                      }),
                                                      _vm.isSubmitted &&
                                                      _vm.$v.contract
                                                        .costCenterWrapType
                                                        .$error
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "invalid-feedback",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      63,
                                                                      _vm.msgWrap
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "12",
                                                lg: "3",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "bcfRate",
                                                      expression: "'bcfRate'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      35,
                                                      "Budget cost factor"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value: "bcfRate",
                                                        expression: "'bcfRate'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "number",
                                                      step: "0.01",
                                                      min: "1",
                                                      placeholder: "1.0",
                                                      disabled:
                                                        _vm.isEditable() ===
                                                        false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .companyCostFactor,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "companyCostFactor",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.companyCostFactor",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "info-message",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        [
                                                          _c("info", {
                                                            attrs: { size: 16 },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "label",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                36,
                                                                "budget cost = salary*factor"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "12",
                                                lg: "3",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "salType",
                                                      expression: "'salType'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      535,
                                                      "Salary type"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("b-form-select", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value: "salType",
                                                        expression: "'salType'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      options:
                                                        _vm.salaryTypeOptions,
                                                      disabled:
                                                        _vm.isEditable() ===
                                                        false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract.salaryType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "salaryType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.salaryType",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.isFilmSingle && !_vm.isFilmSingleFree
                                    ? _c(
                                        "b-row",
                                        [
                                          _c("b-col", { attrs: { sm: "12" } }, [
                                            _c(
                                              "fieldset",
                                              { staticClass: "card-inside" },
                                              [
                                                _c(
                                                  "legend",
                                                  {
                                                    staticClass: "card-inside",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          37,
                                                          "Document package"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-row",
                                                  {
                                                    staticClass:
                                                      "inline-flex align-items-center",
                                                  },
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "6",
                                                          xl: "6",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "uni-for",
                                                                rawName:
                                                                  "v-uni-for",
                                                                value:
                                                                  "documentPackageId",
                                                                expression:
                                                                  "'documentPackageId'",
                                                              },
                                                            ],
                                                            attrs: {
                                                              label:
                                                                _vm.FormMSG(
                                                                  38,
                                                                  "Assign document package"
                                                                ),
                                                            },
                                                          },
                                                          [
                                                            _c("v-select", {
                                                              staticClass:
                                                                "fix-clearable-disabled",
                                                              class: {
                                                                "is-invalid":
                                                                  _vm.isHandleSendPackage &&
                                                                  _vm.$v
                                                                    .contract
                                                                    .documentPackageId
                                                                    .$error,
                                                              },
                                                              attrs: {
                                                                disabled:
                                                                  _vm.isEditable() ===
                                                                  false,
                                                                options:
                                                                  _vm.documentPackageOptions,
                                                                id: "documentPackageId",
                                                                label: "name",
                                                                reduce: (
                                                                  option
                                                                ) => option.id,
                                                                clearable: false,
                                                              },
                                                              on: {
                                                                "option:selected":
                                                                  _vm.selectDocumentPackage,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.contract
                                                                    .documentPackageId,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.contract,
                                                                      "documentPackageId",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "contract.documentPackageId",
                                                              },
                                                            }),
                                                            _vm.isHandleSendPackage &&
                                                            !_vm.$v.contract
                                                              .documentPackageId
                                                              .greaterThanZero
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "invalid-feedback",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            69,
                                                                            "Please, choose a document package"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "6",
                                                          xl: "6",
                                                        },
                                                      },
                                                      [
                                                        _vm.contract
                                                          .documentPackageId > 0
                                                          ? _c(
                                                              "b-row",
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      sm: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "btn-rounded",
                                                                        on: {
                                                                          click:
                                                                            _vm.handleViewDocumentPackage,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "icon",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "eye",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "#5B6164D6",
                                                                                    size: 16,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "label",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                _vm._s(
                                                                                  _vm.FormMSG(
                                                                                    39,
                                                                                    "View document package"
                                                                                  )
                                                                                ) +
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.hideSendPackageBtn
                                                          ? _c(
                                                              "b-row",
                                                              {
                                                                staticClass:
                                                                  "pt-3",
                                                              },
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      sm: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "btn-rounded",
                                                                        on: {
                                                                          click:
                                                                            _vm.handleResendDocumentPackage,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "icon",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "files",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "#5B6164D6",
                                                                                    size: 16,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "label",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                _vm._s(
                                                                                  _vm.FormMSG(
                                                                                    42,
                                                                                    "Send new document package"
                                                                                  )
                                                                                ) +
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("memo-deal", {
                            ref: "memo",
                            attrs: {
                              "edit-data": _vm.getMemoDealInfoFromContract(),
                              "disable-edit": !_vm.isEditable(),
                              "start-date-update": _vm.contract.startDate,
                              "end-date-update": _vm.contract.endDate,
                              "days-foreseen-update": _vm.contract.daysForeseen,
                              "employee-status-update":
                                _vm.contract.employeeStatus,
                              "cost-center-preps-type-update":
                                _vm.contract.costCenterPrepsType,
                              "cost-center-shooting-type-update":
                                _vm.contract.costCenterShootingType,
                              "cost-center-wrap-type-update":
                                _vm.contract.costCenterWrapType,
                              "company-cost-factor-update":
                                _vm.contract.companyCostFactor,
                              "document-package-id-update":
                                _vm.contract.documentPackageId,
                              "is-submitted": _vm.isSubmitted,
                              "fill-class-container": "",
                              "show-pay-code": _vm.showPayCode,
                              "hide-actions": "",
                            },
                            on: {
                              "memo-deal:save-template":
                                _vm.saveAsDealMemoTemplate,
                              change: _vm.handleMemoDealChange,
                              "memo-deal:fields:invalid":
                                _vm.isInvalidateFields,
                              "memo-deal:fields:clear-error":
                                _vm.clearFieldsError,
                              "memo-deal:department-function:required":
                                _vm.handleCcn2642,
                            },
                          }),
                          _vm.isEditable()
                            ? _c(
                                "b-row",
                                {
                                  staticClass:
                                    "d-flex justify-content-center pb-4",
                                },
                                [
                                  !_vm.hideBtnDelete
                                    ? _c(
                                        "b-col",
                                        {
                                          staticClass: "mb-2",
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: `${_vm.activePaste ? 2 : 3}`,
                                            xl: `${_vm.activePaste ? 2 : 3}`,
                                          },
                                        },
                                        [
                                          !_vm.isFilmSingle
                                            ? _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    block: "",
                                                    disabled: _vm.disableDelete,
                                                    variant: "danger",
                                                    size: "md",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.delContract()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          31,
                                                          "Delete contract"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.hideBtnCopy
                                    ? _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: `${_vm.activePaste ? 2 : 3}`,
                                            xl: `${_vm.activePaste ? 2 : 3}`,
                                          },
                                        },
                                        [
                                          !_vm.isFilmSingle
                                            ? _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    block: "",
                                                    size: "md",
                                                    variant:
                                                      "outline-secondary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.copyContract()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          32,
                                                          "Copy contract"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.activePaste
                                    ? _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: `${_vm.activePaste ? 2 : 3}`,
                                            xl: `${_vm.activePaste ? 2 : 3}`,
                                          },
                                        },
                                        [
                                          _vm.activePaste
                                            ? _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    block: "",
                                                    variant:
                                                      "outline-secondary",
                                                    size: "md",
                                                  },
                                                  on: {
                                                    click: _vm.pasteContract,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          33,
                                                          "Paste contract"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.hideBtnSaveTemplate
                                    ? _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: `${_vm.activePaste ? 2 : 3}`,
                                            xl: `${_vm.activePaste ? 2 : 3}`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "px-4",
                                              attrs: {
                                                size: "md",
                                                variant: "outline-secondary",
                                                block: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.saveAsDealMemoTemplate(
                                                    $event
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      194,
                                                      "Save as deal memo template"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        sm: "12",
                                        md: "12",
                                        lg: `${_vm.activePaste ? 2 : 3}`,
                                        xl: `${_vm.activePaste ? 2 : 3}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            block: "",
                                            disabled: _vm.disableSave,
                                            type: "submit",
                                            size: "md",
                                            variant: "outline-primary",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(30, "Save contract")
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }